<template>
    <div class="laudos">
        <laudos />
    </div>
</template>
<script>
import Laudos from '../components/laudos.vue'
export default {
    name: 'LaudosView',
    components: {
        Laudos,
    }
}
</script>
<style scoped>
    .laudos{
        /*background-image: url("../assets/bg/bg-laudos.jpg");*/
        background-size: cover;
        width: 100%;
        min-height: 84vh;
        height: auto;
        background-color: #141526;
        margin-top: 12vh;
    }
   
</style>