<template>
    <div>
        <header>
            <h1>Solicite um projeto conosco!</h1>
        </header>
        <main>
            <div class="pjt">
                <div class="title">
                    Projetos FTTH
                </div>
                <div class="line"></div>
                <div class="text">
                    <ul class="custom-list">
                        <li>Planejamento de Rede Óptica</li>    
                        <li>Design de Infraestrutura de Fibra Óptica</li>    
                        <li>Análise de Viabilidade Técnica e Econômica</li>    
                        <li>Implementação e Instalação de Redes FTTH</li>    
                        <li>Testes e Manutenção de Redes Ópticas</li>    
                    </ul>
                </div>
                <div class="botao">
                    <a href="https://wa.me/5533999628296?text=Vim atrav&eacute;s do site, gostaria de solicitar um projeto FTTH" target="blank">  <button>Solicitar projeto</button></a>  
                </div>
            </div>

            <div class="divisoria"></div>

            <div class="pjt">
                <div class="title">
                    Projetos de Compartilhamento
                </div>
                <div class="line"></div>
                <div class="text">
                    <ul class="custom-list">
                        <li>Análise de Viabilidade de Compartilhamento de Infraestrutura</li>    
                        <li>Projeto de Compartilhamento de Postes para Passagem de Cabos</li>    
                        <li>Regularização e Autorização junto às Concessionárias de Energia</li>    
                        <li>Projeto de Compartilhamento de Infraestrutura entre Provedores de Telecomunicações</li>    
                        <li>Dimensionamento e Planejamento de Capacidade da Infraestrutura Compartilhada</li>    
                    </ul>
                </div>
                <div class="botao">
                    <a href="https://wa.me/5533999628296?text=Vim atrav&eacute;s do site, gostaria de solicitar um projeto de compartilhamento" target="blank">  <button>Solicitar projeto</button></a>  
                </div>
            </div>
            
            <!--<div class="divisoria"></div>-->

            <div class="pjt">
                <div class="title">
                    Projetos SPDA
                </div>
                <div class="line"></div>
                <div class="text">
                    <ul class="custom-list">
                        <li>Estudo de Viabilidade Técnica</li>    
                        <li>Projeto Executivo de Sistema de Proteção contra Descargas Atmosféricas</li>    
                        <li>Análise e Dimensionamento de Estruturas de Captação e Aterramento</li>    
                        <li>Inspeção e Manutenção de Sistemas de Proteção contra Descargas Atmosféricas</li>    
                        <li>Elaboração de Laudos Técnicos e Certificação de Conformidade</li>    
                    </ul>
                </div>
                <div class="botao">
                    <a href="https://wa.me/5533999628296?text=Vim atrav&eacute;s do site, gostaria de solicitar um projeto SPDA" target="blank">  <button>Solicitar projeto</button></a>  
                </div>
            </div>
            
            <div class="divisoria"></div>

            <div class="pjt" id="margem">
                <div class="title">
                    Projetos Elétricos
                </div>
                <div class="line"></div>
                <div class="text">
                    <ul class="custom-list">
                        <li>Projeto de Instalações Elétricas Residenciais</li>    
                        <li>Projeto de Instalações Elétricas Comerciais</li>    
                        <li>Projeto de Instalações Elétricas Industriais</li>    
                        <li>Estudo de Carga e Dimensionamento de Quadros Elétricos</li>    
                        <li>Projeto de Iluminação e Sistemas de Controle</li>    
                    </ul>
                </div>
                <div class="botao">
                    <a href="https://wa.me/5533999628296?text=Vim atrav&eacute;s do site, gostaria de solicitar um projeto el&eacute;trico" target="blank">  <button>Solicitar projeto</button></a>  
                </div>
            </div>
        </main>

    </div>
</template>
<script>
export default {
    name: 'projetos'
}
</script>

<style scoped>
    header{
        font-family: "fieldwork", Sans-serif;
        font-size: 26px;
        padding-left: 10px;
        padding-top: 10px;
        color: rgb(0, 225, 255);
    }
    main{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
    }
    .pjt{
        padding-left: 30px;
        margin-top: 50px;
        width: 40%;
    }
    .title{
        font-family: "fieldwork", Sans-serif;
        font-size: 26px;
        color: rgb(0,255,255);
    }
    .line{
        margin-top: 5px;
        height: 3px;
        width: 20%;
        background-color: aliceblue;
        animation: crescer_linha 1s;
    }
    .text{
        color: aliceblue;
        font-size: 15px;
        font-family: "fieldwork", Sans-serif;
        margin-top: 30px;
    }
    .divisoria{
        width: 3px;
        height: 200px;
        background-color: aliceblue;
        margin-top: 20px;
        margin-left: 10px;
        animation: crescer_divisoria 1s ;
    }
    button{
        margin-top: 20px;
        border: none;
        height: 40px;
        width: 180px;
        font-family: "Montserrat";
        font-weight: bold;
        background-color: rgb(13, 255, 0);
        border-radius: 10px / 30px;
        transition: .3s linear;
        cursor: pointer;
    }
    .botao{
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .custom-list{
        list-style: none ;
    }
    .custom-list li::before {
    content: "¬"; /* Adicione o símbolo do marcador desejado */
    color: rgb(0, 225, 255); /* Altere a cor do marcador */
    font-size: 20px; /* Altere o tamanho do marcador */
    margin-right: 5px; /* Adicione um espaçamento entre o marcador e o texto */
    }
    #margem{
        margin-bottom: 60px;
    }

    

    /*  ANIMAÇÕES   */

    button:hover{
        scale: 1.1;
    }

    @keyframes crescer_divisoria{
        from{
            height: 0;
        }
        to{
            height: 200px;
        }
    }
    @keyframes crescer_linha{
        from{
            width: 0;
        }
        to{
            width: 20%;
        }
    }

    /* MOBILE */

    @media (max-width: 480px) {
        .divisoria{
            display: none;
        }
        main{
            display: block;
            padding: 15px;
            font-size: 26px;
        }
        .pjt{
            width: 100%;
            padding-left: 0px;
        }
        .botao{
            display: flex;
            justify-content: center;
            align-items: center;
        }
        header h1{
            text-align: center;
        }
        .title{
            text-align: center;
        }
        .line{
            width: 100%;
        }
        @keyframes crescer_linha{
        from{
            width: 0;
        }
        to{
            width: 100%;
        }
    }


    }
    @media (hover: none){
        button:hover{
            background-color: rgb(13, 255, 0);
            color: black;
            border: none;
            scale: 1;
        }
    }
    

</style>