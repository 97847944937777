<template>
    <div class="cursos">
        <treinamentos />
    </div>
</template>
<script>
import Treinamentos from '../components/treinamentos.vue'

export default {
    name:'CursosView',
    components: {
        Treinamentos
        
    }
}
</script>
<style scoped>
    .cursos{
        margin-top: 12vh;
        min-height: 84vh;
        background-color: #141526;
        width: 100%;
    }
</style>