<template>
    <header>
        <h1>Laudos e perícias</h1>
    </header>
    <main>
        <div class="container">
            <h2>Laudos de Instalações Elétricas</h2>
            <div class="line"></div>
            <ul class="itens">
                <li>Laudo de Inspeção e Avaliação de Segurança Elétrica</li>
                <li>Laudo de Verificação de Conformidade às Normas Técnicas</li>
                <li>Laudo de Aterramento Elétrico</li>
                <li>Laudo de Análise de Curto-Circuito e Seletividade</li>
                <li>Laudo de Avaliação de Eficiência Energética</li>
            </ul>
            <div class="botao">
                <a href="https://wa.me/5533999628296?text=Vim atrav&eacute;s do site, gostaria de solicitar um laudo" target="blank">
                    <button>Solicitar</button>
                </a>
            </div>
        </div>

        <div class="divisoria"></div>

        <div class="container">
            <h2>Laudos Técnicos SPDA e Aterramento</h2>
            <div class="line"></div>
            <ul class="itens">
                <li>Inspeção SPDA</li>
                <li>Verificação de Conformidade SPDA</li>
                <li>Medição de Resistência de Aterramento</li>
                <li>Avaliação do Sistema de Aterramento</li>
                <li>Análise de Risco e Vulnerabilidade SPDA</li>
            </ul>
            <div class="botao">
                <a href="https://wa.me/5533999628296?text=Vim atrav&eacute;s do site, gostaria de solicitar um laudo" target="blank">
                    <button>Solicitar</button>
                </a>
            </div>
        </div>

        

        <div class="container">
            <h2>Laudos Técnicos em Atmosferas Explosivas</h2>
            <div class="line"></div>
            <ul class="itens">
                <li>Inspeção e Classificação de Áreas</li>
                <li>Análise de Riscos e Vulnerabilidades</li>
                <li>Avaliação de Equipamentos Existentes</li>
                <li>Certificação de Conformidade às Normas</li>
                <li>Recomendações e Medidas de Segurança</li>
            </ul>
            <div class="botao">
                <a href="https://wa.me/5533999628296?text=Vim atrav&eacute;s do site, gostaria de solicitar um laudo" target="blank">
                    <button>Solicitar</button>
                </a>
            </div>
        </div>

        <div class="divisoria"></div>

        <div class="container">
            <h2>Perícias no Setor de Energia, Telecom e Segurança do Trabalho</h2>
            <div class="line"></div>
            <ul class="itens">
                <li>Perícia em Acidentes Elétricos</li>
                <li>Análise de Falhas em Telecomunicações</li>
                <li>Avaliação de Riscos e Conformidade em Instalações Elétricas</li>
                <li>Perícia em Incidentes de Segurança do Trabalho</li>
                <li>Análise de Danos e Sinistros em Equipamentos e Infraestrutura</li>
            </ul>
            <div class="botao">
                <a href="https://wa.me/5533999628296?text=Vim atrav&eacute;s do site, gostaria de solicitar um laudo" target="blank">
                    <button>Solicitar</button>
                </a>
            </div>
        </div>
    </main>
</template>
<script>
    export default{
        name: 'laudos',
    }
</script>
<style scoped>

    header{
        font-size: 26px;
        font-family: "fieldwork", Sans-serif;
        padding: 10px;
        color: rgb(0, 225, 255);
    }
    h2{
        color: rgb(0, 225, 255);
        font-size: 30px;
        font-family: "fieldwork", Sans-serif;
        margin-bottom: 0px;
        font-weight: bold;
    }
    .vertical{
        height: 150px;
        width: 3px;
        margin: 10px;
        background-color: aliceblue;

    } 
    main{
        display: flex;
        justify-content: center;
        align-items: center;
        height: auto;
        flex-wrap: wrap;
        padding-bottom: 30px;
        margin-top: -50px;
    }
    .botao{
        justify-content: center;
        align-items: center;
        display: flex;
    }
    .itens li::before{
        content: "▶"; 
        color: rgb(0, 225, 255); 
        font-size: 10px;
        margin-right: 10px;
         
    }
    .itens li{
        margin-bottom: 6px;
    }
    .itens{
        list-style: none;
        color: aliceblue;
        font-size: 15px;
        font-family: "fieldwork", Sans-serif;
        padding-top: 30px;
    }
    button{
        border: none;
        height: 40px;
        width: 180px;
        margin-top: 10px;
        border-radius: 10px / 30px;
        background-color: rgb(13, 255, 0);
        font-family: 'Montserrat';
        transition: .3s linear;
        cursor: pointer;
        font-weight: bold;
    }
    .container{
        width: 40%;
        margin-top: 50px;
        padding: 30PX;
        
    }

    button:hover{
        scale: 1.1;
    }

    .line{
        margin-top: 5px;
        height: 3px;
        width: 20%;
        background-color: aliceblue;
        animation: crescer_linha 1s;
    }
    .divisoria{
        width: 3px;
        height: 200px;
        background-color: aliceblue;
        margin-top: 20px;
        margin-left: 10px;
        animation: crescer_divisoria 1s ;
    }

    /*  ANIMAÇÕES   */

    @keyframes crescer_divisoria{
        from{
            height: 0;
        }
        to{
            height: 200px;
        }
    }
    @keyframes crescer_linha{
        from{
            width: 0;
        }
        to{
            width: 20%;
        }
    }
   


    /*  MOBILE  */

    @media (max-width: 480px) {
        main{
            display: block;
            padding: 10px;
            height: auto;
            overflow: hidden;
        }
        .container{
            width: 90%;
            margin-bottom: 50px;
            padding: 20px;
        }
        .container h2{
            text-align: center;
        }
        .divisoria{
            display: none;
        }
        header{
            margin-bottom: 30px;
            font-size: 21px;
        }
        header h1{
            text-align: center;
        }
        h2{
            font-size: 24px;
            margin-bottom: 20px;
        }
        .line{
            width: 100%;
        }
        @keyframes crescer_linha{
        from{
            width: 0;
        }
        to{
            width: 100%;
        }
    }


    }
    @media (hover: none){
        button:hover{
            background-color: rgb(13, 255, 0);
            color: black;
            border: none;
            scale: 1;
        }
    }

</style>