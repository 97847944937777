import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ProjetosView from '../views/ProjetosView.vue'
import LaudosView from '../views/LaudosView.vue'
import CursosView from '../views/CursosView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path:'/projetos',
    name:'projetos',
    component: ProjetosView
  },
  {
    path:'/laudos',
    name:'laudos',
    component: LaudosView
  },
  {
    path:'/cursos',
    name:'cusros',
    component: CursosView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); // Rola para o topo da página
  next();
});

export default router
