<template>
  <div class="container">
  <Carousel :autoplay="2000" :wrap-around="true" id="carrossel">
    <Slide v-for="item in itens" :key="item">
      <div class="carousel__item">
        <img :src="require(`@/assets/img/${item}`)" alt="Imagem" />
      </div>
    </Slide>
      <template #addons>
    </template>
  </Carousel>
</div>
</template>


<script>
import { defineComponent } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'imagens',
  components: {
    Carousel,
    Slide,
    /*Pagination,*/
  },
  data() {
    return {
      itens: ['um.jpg', 'dois.jpg', 'tres.jpg'],
    }
  },
})
</script>

<style scoped>
  .container {
width: 100%;
overflow: hidden;
margin-bottom: 0  px;
}

.carousel__item {
width: 100%;
display: flex;
justify-content: center;
}

.carousel__item img {
  width: 100%;
height: 400px;
object-fit: cover;
object-position:center;
}


</style>

