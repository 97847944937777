<template>
  <div class="home">

    <!-- <imagens /> -->

    <main>
      <div class="container">
        <div class="logo">
          <img src="../assets/logo.png" alt="logo">
        </div>
        <div class="texto">
          <p>+15 anos de experiência, dedicando tempo e estudo, se empenhando para resultados positivos e vitórias alcançáveis</p>
        </div>
      </div>
    </main>
    <especialidades />
    <um />
    
  </div>
</template>

<script>
// @ is an alias to /src
import especialidades from '@/components/especialidades.vue'
import Imagens from '../components/imagens.vue'
import Um from '../components/um.vue' 


export default {
  name: 'HomeView',
  components: {
    especialidades,
    Imagens,
    Um,
    
  }
}
</script>
<style scoped>
  .home{
    margin-top: 12vh;
  }
  .container{
    background-color: rgb(143, 187, 187);
    min-height: 60vh;
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  main{
    display: flex;
    justify-content: center;
  }
  .logo{
    width: 45%;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  img{
    display: flex;
    justify-content: center;
    animation: img 2s ease-out;
  }
  .texto{
    width: 45%;
    font-family: 'Gruppo';
    font-weight: bold;
    font-size: 26px;
    text-align: center;
  }
  .texto p{
    animation: texto 3s;
    text-align: center;
  }

  @keyframes texto{
    from{
      opacity: 0;
    }
    to{
      opacity: 100;
    }
  }
  @keyframes img{
    from{
      opacity: 0;
      scale: .9;
    }
    to{
      opacity: 100%;
      scale: 1;
    }
  }

  @media (max-width: 480px){
    .container{
      display: block;
      justify-content: center;
      align-items: center;
      padding: 10px;
      min-height: auto;
      padding-bottom: 30px;
    }
    .logo{
      width: 100%;
    }
    
    .texto{
      margin-top: 30px;
      width: 100%;
      text-align: justify;
      font-size: 20px;
    }
  }


</style>
