<template>
    <div class="base">
            <h1>NOSSAS ESPECIALIDADES</h1>
        <ul class="container">
            <button @click="sec1">
                <p>Projetos FTTH</p>
            </button>
            
            <button @click="sec3">
                <p>Projetos SPDA</p>
            </button>
            
            <button @click="sec2">
                <p>Projetos de Compartilhamento</p>
            </button>
            
            <button @click="sec4">
                <p>Projetos Elétricos</p>
            </button>
            
            <button @click="sec5">
                <p>Laudos de Instalações Elétricas</p>
            </button>
            
            <button @click="sec6">
                <p>Laudos Técnicos SPDA e Aterramento</p>
            </button>
            
            <button @click="sec7">
                <p>Laudos Técnicos em Atmosferas Explosivas</p>
            </button>
            
            <button @click="sec8">
                <p>Perícias</p>
            </button>
                        
        </ul>

    </div>
</template>

<script>
import VueScrollTo from 'vue-scrollto';


export default {
    name: 'especialidades',
    methods: {
        sec1() {
            const offset = -window.innerHeight * 0.12;
            VueScrollTo.scrollTo('#sec1', 500, { easing: 'ease-in-out', offset  });
        },
        sec2() {
            const offset = -window.innerHeight * 0.12;
            VueScrollTo.scrollTo('#sec2', 600, { easing: 'ease-in-out', offset  });
        },
        sec3() {
            const offset = -window.innerHeight * 0.12;
            VueScrollTo.scrollTo('#sec3', 700, { easing: 'ease-in-out', offset  });
        },
        sec4() {
            const offset = -window.innerHeight * 0.12;
            VueScrollTo.scrollTo('#sec4', 800, { easing: 'ease-in-out', offset  });
        },
        sec5() {
            const offset = -window.innerHeight * 0.12;
            VueScrollTo.scrollTo('#sec5', 900, { easing: 'ease-in-out', offset  });
        },
        sec6() {
            const offset = -window.innerHeight * 0.12;
            VueScrollTo.scrollTo('#sec6', 1000, { easing: 'ease-in-out', offset  });
        },
        sec7() {
            const offset = -window.innerHeight * 0.12;
            VueScrollTo.scrollTo('#sec7', 1100, { easing: 'ease-in-out', offset  });
        },
        sec8() {
            const offset = -window.innerHeight * 0.12;
            VueScrollTo.scrollTo('#sec8', 1200, { easing: 'ease-in-out', offset });
        },
        
    }
}
</script>

<style scoped>

    button{
        font-family: 'Montserrat';
        padding: 2px;
        height: 60px;
        width: 200px;
        margin: 10px;
        background-color: rgb(13, 255, 0);
        border-radius: 10px / 10px;
        border: none;
        cursor: pointer;
        transition: .4s linear;
    }
    button:hover{
        background-color: transparent;
        border: aliceblue 2px solid;
        color: aliceblue;
    }

    h1{
        
        display: flex;
        width: 100%;
        background-color: rgb(0, 55, 118);
        justify-content: center;
        color: aliceblue;
        font-family: 'Montserrat';
        padding-bottom: 50px;
        padding-top: 60px;
    }
    ul{
        display: flex;
        justify-content: space-around;
        flex-direction: row;
        font-family: 'Montserrat';
        flex-wrap: wrap;
        
        padding-bottom: 40px;
        
    }

    .container{
        padding-left: 14%;
        padding-right: 14%;
        background-color: rgb(0, 55, 118);
    }

    .item p{
        font-size: 12px;
    }


    /*  VERSÃO MOBILE   */

@media (max-width: 480px) {
    .container {
            padding: 0;
            padding-bottom: 30px;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center; /* Centraliza os botões horizontalmente */
        }

        .container button {
            flex-basis: 40%; /* Define a largura de cada botão para ocupar 50% da largura do container */
        }

        h1 {
            font-size: 26px;
        }

        button {
            /* Estilos adicionais para ajustar o tamanho dos botões */
            height: 52px;
            width: 140px;
        }

        .base {
            /* Estilos adicionais para ajustar o espaçamento */
            padding: 0px;
        }
    }
    @media (hover: none){
        button:hover{
            background-color: rgb(13, 255, 0);
            color: black;
            border: none;
        }
    }


</style>