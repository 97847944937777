<template>
  <div>
    <nav :class="{ 'scrolled': isScrolled }">
    <router-link to="/" ><div class="logo"><img src="./assets/logo.png" alt="" @click="ativo=false"></div></router-link>
    <ul class="nav">
      <li class="vazio"></li> 
      <li class="vazio"></li>
      <li class="vazio"></li> 
      <li class="vazio"></li>
      <li class="vazio"></li>
      <li class="vazio"></li>
      <router-link to="/projetos" class="link">PROJETOS</router-link>
      <router-link to="/laudos" class="link">LAUDOS E PERÍCIAS</router-link>
      <router-link to="/cursos" class="link">CURSOS E TREINAMENTOS</router-link> 
      <router-link to="/about" class="link">SOBRE</router-link>  
    </ul>
    <a class="hamburger" @click="ativar()">
      <span></span>
      <span></span>
      <span></span>
    </a>
  </nav>
  <transition name="fade">
    <div v-if="ativo"  class="navmob">
    <router-link to="/projetos" class="link" @click="ativo=false">PROJETOS</router-link>
    <router-link to="/laudos" class="link" @click="ativo=false">LAUDOS E PERÍCIAS</router-link>
    <router-link to="/cursos" class="link" @click="ativo=false">CURSOS E TREINAMENTOS</router-link>
    <router-link to="/about" class="link" @click="ativo=false">SOBRE</router-link>  
  </div>
  </transition>
  
  <router-view/>
  <footer>
    <div class="alinhar">
      <p>LSM Engenharia</p>
      <p>©2023 Todos os Direitos Reservados</p>
      <p>CNPJ: 50.957.526/0001-32</p>
    </div>
  </footer>
  </div>
</template>

<script>
  export default{
   data(){
    return{
      ativo: false,
      isScrolled: false
    }
   },
   mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
   destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
   methods:{
      ativar(){
        this.ativo = !this.ativo;
      },
      handleScroll() {
      this.isScrolled = window.pageYOffset > 50;
    }
   }
  
  }
</script>

<style scoped>

nav.scrolled {
  box-shadow: 0 2px 4px rgb(255, 253, 253);
}
  
  .logo{
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    width: auto;
    float: left;
    
  }
  .logo img{
    height: 80px;
    width: 100px;
  }
  nav{
    background-color: rgb(0, 55, 118);
    display: flex;
    height: 12vh;
    align-items: center;
    position: fixed; /* Set the navbar to fixed position */
    top: 0; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    margin-bottom: 12vh;
    z-index: 999;

  }
  ul{
    display: flex;
    justify-content: space-around;
    width: 100%;
    list-style: none;
    color: aliceblue;
    font-family: "Montserrat";
    font-size: 20px;
  }
  nav a.router-link-exact-active {
    color: rgb(0,255,255);
  }
  .link{
    text-decoration: none;
    color: aliceblue;
    display: flex;
    align-items: center;
  }
  footer{
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: rgb(0, 55, 118);
    font-size: small;
    color: aliceblue;
    font-family: 'Montserrat';
    min-height: 4vh;
    height: auto;
    
  }
  .alinhar{
    display: flex;
    justify-content: space-around;
    width: 60%;
  }

  /*  MOBILE  */

  @media (max-width: 480px){
    footer{
      display: flex;
      
    }
    footer p{
      text-align: center;
      align-items: center;
      display: flex;
      font-size: 11px;
    }
    .alinhar{
      width: 100%;
    }


    ul{
      display: none;
    }
    
    .hamburger{
      display: flex;
      flex-direction: column;
      margin-left: 60%;
      scale: 1.5;
      
      
    }
    .hamburger span{
      height: 3px;
      width: 20px;
      background-color: aliceblue;
      margin: 2px;
    }
    

    .navmob{
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 350px;
    flex-direction: column;
    display: flex;
    background-color: rgb(0, 55, 118);
    align-items: center;
    justify-content: center;
    list-style: none;
    color: aliceblue;
    font-family: "Montserrat";
    font-size: 20px;
    position: fixed; /* Set the navbar to fixed position */
    top: 12vh; /* Position the navbar at the top of the page */
    width: 100%; /* Full width */
    }
    .link{
      padding: 30px;
    }


    .fade-enter-active,
    .fade-leave-active{
      transition: opacity 0.5s;
    }

    .fade-enter,
    .fade-leave-to{
      opacity: 0;
    }
}

</style>
<style>
  *{
    margin: 0;
    padding: 0;
  }
  a{
    text-decoration: none;
    color: black;
  }
</style>
