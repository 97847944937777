<template>
    <div class="projetos">
        <projetos />
    </div>
</template>
<script>
import projetos from '../components/projetos.vue'


export default {
    name:'ProjetosView',
    components: {
        projetos
    }
}
</script>

<style scoped>
    .projetos{
        /*background-image: url("../assets/bg/bg-projetos.jpg");    */
        background-size: cover;
        width: 100%;
        /*height: 84vh;*/
        height: auto;
        min-height: 84vh;
        background-color: #141526;
        margin-top: 12vh;
    }
    
</style>