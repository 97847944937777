<template>
    <header>
        <h1>Conheça nossos cursos e treinamentos</h1>
    </header>
    <main>
        
        <div class="cont">
            <div class="texto">
                <div class="cursos">
                    <h2>Instrutor RT em Treinamentos:</h2>
                    <p>NR6, NR10, NR11, NR12, NR17, NR18, NR23, NR33, NR35 e Treinamentos RAC</p>
                </div>
                <div class="treinamentos">
                    <h2>Palestrante e Instrutor em Treinamentos:</h2>
                    <p>Redes de Cabeamento Óptico FTTx e Cabeamento Estruturado</p>
                </div>
                <div class="botao">
                    <button><a href="https://wa.me/5533999628296?text=Vim atrav&eacute;s do site, gostaria de mais informa&ccedil;&otilde;es sobre cursos e treinamentos" target="blank">Mais informações</a></button>
                </div>
            </div>
            <div class="imagens">
                <div class="container">
                    <Carousel :autoplay="3500" :wrap-around="true" id="carrossel">
                        <Slide v-for="item in itens" :key="item">
                            <div class="carousel__item">
                                <img :src="require(`@/assets/img/${item}`)" alt="Imagem" />
                            </div>
                        </Slide>
                            <template #addons>
                        </template>
                    </Carousel>
                </div>
            </div>
        </div>
    </main>
</template>
<script>

import { Carousel, Slide } from 'vue3-carousel'
import { defineComponent } from 'vue'

import 'vue3-carousel/dist/carousel.css'

export default {
    name:'treinamentos',
    components:{
        Carousel,
        Slide,
        /*Pagination,*/
    },
    data() {
    return {
      itens: ['curso1.jpg', 'curso4.jpg', 'curso2.jpg', 'curso5.jpg', 'curso3.jpg' ],
    }
  }
}
</script>
<style scoped>
    main{
        display: flex;
        
    }
    .imagens{
        width: 45%;
    }
    .texto{
        width: 45%;
    }
    .cont{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    header{
        font-family: "fieldwork", Sans-serif;
        font-size: 26px;
        padding-left: 10px;
        padding-top: 10px;
        color: rgb(0, 225, 255);
    }
    .texto p{
        color: aliceblue;
        font-size: 15px;
        font-family: "fieldwork", Sans-serif
    }
    .texto h2{
        font-family: "fieldwork", Sans-serif;
        font-size: 26px;
        color: rgb(0,255,255);
    }

    button{
        margin-top: 20px;
        border: none;
        height: 40px;
        width: 180px;
        font-family: "Montserrat";
        font-weight: bold;
        background-color: rgb(13, 255, 0);
        border-radius: 10px / 30px;
        transition: .3s linear;
        cursor: pointer;
        animation: aparecer_titulo .5s ease-in-out;
    }
    .botao{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    button:hover{
        scale: 1.1;
    }
    .cursos{
        margin-bottom: 50px;
        animation: aparecer_texto 1s ease-in;
        animation-delay: 0;
    }
    .treinamentos{
        animation: aparecer_texto 1s ease-in;
        animation-delay: 0;
    }
    h1{
        animation: aparecer_titulo 1s ease-in;
    }


    /*  ANIMAÇÕES   */

    @keyframes aparecer_slide{
        0%{
            opacity: 0;
        }
        90%{
            opacity: 0;
        }
        100%{
            opacity: 100%;
        }
    }
    @keyframes aparecer_texto{
        0%{
            opacity: 0;
        }
        50%{
            opacity: 0;
        }
        100%{
            opacity: 100%;
        }
    }
    @keyframes aparecer_titulo{
        from{
            opacity: 0;
        }
        to{
            opacity: 100%;
        }
    }




    .container {
    width: 100%;
    overflow: hidden;
    margin-bottom: 0px;
    animation: aparecer_slide 1.2s linear;
    
    
}

.carousel__item {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 80px;
}

.carousel__item img {
    width: 100%;
    height:auto;
    object-fit:cover;
    object-position:center;
}

@media (max-width: 480px) {
    main{
        margin-top: 50px;
        display: block;
        width: 100%;
        overflow: hidden;
    }
    .cont{
        display: block;
    }
    .imagens{
        width: 100%;
    }
    .texto{
        width: 90%;
        padding: 10px;
    }
    header{
        font-size: 20px;
        justify-content: center;
    }
    header h1{
        text-align: center;
    }
    .carousel__item{
        margin: 30px;
    }
    .container{
        margin-bottom: 40px;
    }
    .cursos h2{
        text-align: center;
    }
    .cursos p{
        text-align: justify;
        margin-top: 10px;
    }
}
@media (hover: none){
        button:hover{
            background-color: rgb(13, 255, 0);
            color: black;
            border: none;
            scale: 1;
        }
    }


</style>