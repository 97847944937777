<template>
   <div>
    <main>
        <div class="container" id="sec1">
        <div class="imagem"><img src="../assets/inicio/0.png" alt="" class="teste"></div>
        <div class="linha"></div>
        <div class="texto">
            <h1>Projetos FTTH</h1>
            <p>Projeto de redes ópticas para provedores de serviços de internet, contendo georreferenciamento, diagrama de ligações e lista de materiais que serão utilizados para a execução e performance do seu provedor</p>
            <div class="btn">
                <router-link to="/projetos" class="link">
                    <button>Mais informações</button>
                </router-link>       
            </div>
            <div class="base-img"><div class="imagem-mob"><img src="../assets/inicio/0.png" alt="" class="teste"></div></div>
        </div>
    </div>

    <div class="container" id="sec2">
        <div class="texto">
            <h1>Projetos de Compartilhamento</h1>
            <p>Projeto de compartilhamento de infraestrutura exigido pelas concessionárias de energia para autorização ou regularização para uso dos postes de energia para passagem de cabos por parte dos provedores e Operadoras de Telecomunicações</p>
            <div class="btn">
                <router-link to="/projetos" class="link">
                    <button>Mais informações</button>
                </router-link>       
            </div>
        </div>
        <div class="linha"></div>
        <div class="imagem"><img src="../assets/inicio/3.png" alt=""></div>
        <div class="base-img"><div class="imagem-mob"><img src="../assets/inicio/3.png" alt=""></div></div>
    </div>

    <div class="container" id="sec3">
        <div class="imagem"><img src="../assets/inicio/5.png" alt="" class="teste"></div>
        <div class="linha"></div>
        <div class="texto">
            <h1>Projetos SPDA</h1>
            <p>O SPDA (Sistema de Proteção contra Descargas Atmosféricas) é um conjunto de estruturas e soluções que tem a função de proteger pessoas e construções diversas das ações dos raios. No SPDA, o projeto é feito para que haja captação e dissipação das descargas atmosféricas até a terra, em caminho seguro.</p>
            <div class="btn">
                <router-link to="/projetos" class="link">
                    <button>Mais informações</button>
                </router-link>       
            </div>
            <div class="base-img"><div class="imagem-mob"><img src="../assets/inicio/5.png" alt="" class="teste"></div></div>
        </div>
    </div>

    <div class="container" id="sec4">
        <div class="texto">
            <h1>Projetos Elétricos</h1>
            <p>O projeto elétrico é a representação gráfica e escrita, de forma detalhada, das instalações elétricas da construção e devem estar de acordo com a Norma Brasileira (NBR 5410). O Projeto Elétrico é essencial em sua obra para que você tenha uma maior segurança em sua casa ou comércio, tendo também uma maior economia de recursos, já que tudo que vai ser necessário em sua obra constará no planejamento, além da economia de tempo durante a execução</p>
            <div class="btn">
                <router-link to="/projetos" class="link">
                    <button>Mais informações</button>
                </router-link>       
            </div>
        </div>
        <div class="linha"></div>
        <div class="imagem"><img src="../assets/inicio/8.png" alt="" class="teste"></div>
        <div class="base-img"><div class="imagem-mob"><img src="../assets/inicio/8.png" alt="" class="teste"></div></div>
    </div>

    <div class="container" id="sec5">
        <div class="imagem"><img src="../assets/inicio/9.png" alt="" class="teste"></div>
        <div class="linha"></div>
        <div class="texto">
            <h1>Laudos de Instalações Elétricas</h1>
            <p>Elaboração de Laudo Técnico de Avaliação das Instalações Elétricas apresentando a situação atual na qual se encontra as instalações elétricas do empreendimento ou residência, é feito medição das grandezas elétricas e detecção de problemas nos painéis e quadros de distribuição de energia elétrica, apontando soluções técnicas para os problemas elétricos encontrado, aferindo sua conformidade com as normas técnicas aplicáveis.</p>
            <div class="btn">
                <router-link to="/laudos" class="link">
                    <button>Mais informações</button>
                </router-link>       
            </div>
            <div class="base-img"><div class="imagem-mob"><img src="../assets/inicio/9.png" alt="" class="teste"></div></div>
        </div>
    </div>

    <div class="container" id="sec6">
        <div class="texto">
            <h1>Laudos Técnicos SPDA e Aterramento</h1>
            <p>Este trabalho tem como objetivo fazer uma avaliação detalhada do SPDA – Sistema de Proteção Contra Descargas Atmosféricas e do Aterramento de Proteção (ABNT NBR 5419:2015) existente na edificação, verificar o estado de conservação das peças e acessórios e checar a sua conformidade com as normas técnicas. 

            É apresentadas as conformidades e não conformidades do SPDA e do Aterramento seguida das sugestões técnicas necessárias para conformidade à norma e/ou detalhes caso seja necessário.  </p>
            <div class="btn">
                <router-link to="/laudos" class="link">
                    <button>Mais informações</button>
                </router-link>       
            </div>
        </div>
        <div class="linha"></div>
        <div class="imagem"><img src="../assets/inicio/12.jpg" alt="" class="teste"></div>
        <div class="base-img"><div class="imagem-mob"><img src="../assets/inicio/12.jpg" alt="" class="teste"></div></div>
    </div>

    <div class="container" id="sec7">
        <div class="imagem"><img src="../assets/inicio/14.jpg" alt="" class="teste"></div>
        <div class="linha"></div>
        <div class="texto">
            <h1>Laudos Técnicos em Atmosferas Explosivas</h1>
            <p>O Laudo Técnico tem por sua finalidade registrar as conformidades e não conformidades das instalações elétricas em atmosferas explosivas deste empreendimento, apontando soluções técnicas para os problemas elétricos encontrado, aferindo sua conformidade com as normas técnicas aplicáveis.</p>
            <div class="btn">
                <router-link to="/laudos" class="link">
                    <button>Mais informações</button>
                </router-link>       
            </div>
            <div class="base-img"><div class="imagem-mob"><img src="../assets/inicio/14.jpg" alt="" class="teste"></div></div>
        </div>
    </div>

    <div class="container" id="sec8">
        <div class="texto">
            <h1>Perícias no setor de energia, telecom e segurança do trabalho</h1>
            <p>As perícias judiciais são levantamentos de provas feitos por responsáveis técnicos da área: ou seja, os peritos judiciais. Seu objetivo é apresentar informações para ajudar a determinar a resolução de uma ação judicial</p>
            <p id="assist">A perícia é, muitas vezes, prova fundamental para a resolução de casos complexos. Isso não apenas no Direito Criminal, mas também, em processos cíveis e trabalhistas, por exemplo. Trata-se de uma análise técnica feita por um profissional capacitado para versar sobre determinado fato, estado ou situação. 

            Mesmo o mais competente dos peritos, entretanto, está sujeito a cometer falhas de toda natureza. Nesse contexto, surge a figura do assistente técnico em perícias. O profissional é quem pode acompanhar a veracidade da avaliação pericial e, em favor da parte, formular quesitos ou hipóteses que possam atestar a segurança e a eficácia do laudo desenvolvido pelo perito no processo. </p>
            <div class="btn">
                <router-link to="/laudos" class="link">
                    <button>Mais informações</button>
                </router-link>       
            </div>
        </div>
        <div class="linha"></div>
        <div class="imagem" id="teste">
            <div class="imagens">
                <div class="containerr">
                    <Carousel :autoplay="2500" :wrap-around="true" id="carrossel">
                        <Slide v-for="item in itens" :key="item">
                            <div class="carousel__item">
                                <img :src="require(`@/assets/img/${item}`)" alt="Imagem" />
                            </div>
                        </Slide>
                            <template #addons>
                        </template>
                    </Carousel>
                </div>
            </div>
        </div>
        <div class="slide_mobile">
            <div class="imagens">
                <div class="containerr">
                    <Carousel :autoplay="2500" :wrap-around="true" id="carrossel">
                        <Slide v-for="item in itens" :key="item">
                            <div class="carousel__item">
                                <img :src="require(`@/assets/img/${item}`)" alt="Imagem" />
                            </div>
                        </Slide>
                            <template #addons>
                        </template>
                    </Carousel>
                </div>
            </div>

        </div>
        <!--<div class="base-img"><div class="imagem-mob"></div></div>-->
    </div>

    </main>
    

   </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel'
import { defineComponent } from 'vue'

import 'vue3-carousel/dist/carousel.css'

export default {
    name: 'um',
    components:{
        Carousel,
        Slide,
        /*Pagination,*/
    },
    data() {
        return {
        itens: ['16.png', '17.png', '18.jpg', '19.png' ],
        }
    }
}
</script>

<style scoped>
    main{
    overflow: hidden;
    }  

    img{
        max-width: 600px;
        max-height: 400px;
    }
    .container{
        background-color: #141526;
        font-family: 'Montserrat';
        padding-top: 50px;
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    h1{
        margin-bottom: 30px;
        color: rgb(0, 255, 255);
        text-align: center;
    }
    p{
        color: aliceblue;
        text-align: justify;
    }
    .linha{
        height: 300px;
        width: 4px;
        background-color: aliceblue;
        margin-left: 10px;
        margin-right: 10px;
    }
    .texto{
        width: 40%;
    }
    .imagem{
        height: 400px;
        width: 600px;
        
        
        /*background-color: antiquewhite; */
        
    }
    .imagem-mob{
        height: 400px;
        width: 600px;
        display: none;
    }
    .btn{
        display: flex;
        justify-content: center;
    }
    button{
        font-family: 'Montserrat';
        margin-top: 45px;
        width: 150px;
        height: 40px;
        border-radius: 5px;
        border: none;
        cursor: pointer;
        transition: .5s ;
        background-color: rgb(13, 255, 0);
        cursor: pointer;
    }

    button:hover{
        background-color: transparent;
        border: aliceblue 2px solid;
        color: aliceblue;
    }

    .link{
        text-decoration: none;
    }
    #assist{
        margin-top: 10px;
    }


    .slide_mobile{
        display: none;
    }


    /*  SLIDES  */
    .containerr {
    width: 100%;
    overflow: hidden;
    margin-bottom: 0px;
    animation: aparecer_slide 1.2s linear;
    }

    .carousel__item {
        width: 100%;
        display: flex;
        justify-content: center;
        
    }

    .carousel__item img {
        width: 100%;
        height:auto;
        object-fit:cover;
        object-position:center;
    }




    /*  MOBILE  */

    @media (max-width: 480px) {
        
        .container{
            display:block;
        }
        .imagem{
            display: none;
        }
        .imagem-mob{
            display: flex;
            height:300px ;
            width: 370px;
        }
        img{
            max-width: 370px;
        }
        .linha{
            display: none;
        }
        .texto{
            width: 100%;
        }
        .base-img{
            margin-top: 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 30px;
        }

        .carousel__item{
        margin-top: 15px;
    }
        .containerr{
        margin-bottom: 40px;
    }

    .slide_mobile{
        display: block;
    }
}

</style>



